import React from 'react';

import SEO from '../../components/seo';
import Navigation from '../../components/navigation';
import { TileSection, Tile } from '../../components/tile-section';

import { graphql } from 'gatsby';
import ServiceAndMaintenance from '../../components/service-and-maintenance';
import Discover from '../../components/discover';
import ATillZ from '../../components/a-till-z-cta';
import { Languages } from '../../tools/languages';

const EnglishSectorsPage = ({ location, data, language = Languages.EN }) => {
  const pathName = location.pathname;

  return (
    <>
      <SEO
        pathName={pathName}
        title={data.page.SEO.title}
        description={data.page.SEO.description}
      />
      <Navigation />
      <main>
        <h1>Sectors</h1>
        <TileSection>
          {data.sectors.nodes.map((sector) => {
            return (
              <Tile
                key={sector.title}
                img={
                  sector.image
                    ? sector.image.childImageSharp.fluid
                    : 'https://via.placeholder.com/640?text=Bavak'
                }
                title="Sectors"
                name={sector.title}
                to={`/sectors/${sector.slug}/`}
              />
            );
          })}
        </TileSection>
        <ServiceAndMaintenance />
        <hr />
        <ATillZ language={language} />
        <Discover language={language} dark={true} />
      </main>
    </>
  );
};

export const query = graphql`
  {
    page: strapiSectorspageEn {
      SEO {
        title
        description
      }
    }
    sectors: allStrapiSectors {
      nodes {
        slug
        title: title_en
        image {
          childImageSharp {
            fluid(maxWidth: 960, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default EnglishSectorsPage;
